import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import FooterBranding from '../components/footer/FooterBranding'
import Aside from '../components/Aside'

const JAideALInternational = (props) => (
  <Layout>

    <div className="container custom-container">

        <div className="content-wrapper title-page">
            <div className="content-wrapper-inner">
                  
                  <h1>J’aide à l'international</h1>
                  {/* <span>{props.location.hostname}{props.location.pathname}</span> */}
              </div>
        </div>


          <div className="container-inner">
          
            <div className="content-wrapper">
            <div className="content-wrapper-inner">
                <div className="content">
                
                  <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/06/world-1024x683-1.jpg" alt=""/>
                  <p>Il s’agit d’actions de solidarité menées dans le monde entier mais également dans tout autre département français (hors ceux cités dans «  J’aide ma ville »).</p>
                </div>

                <aside>
                <Aside/>
                </aside>
                
                
                
                
                
                
            
            </div>
            </div>
          </div>
      </div>
    <FooterBranding/>
  </Layout>
)

export default JAideALInternational
